@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
}

@media (max-height: 620px) {
  h2 {
    font-size: 3.5vh !important;
  }
  #bouton_go {
    margin-top: 5vh;
  }
}

.select_wrap .select_ul li.top {
  background-color: #eafec8;
}

.select_card_version {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 7px;
  text-align: center;
}
.select_card_version input:focus-visible {
  border: 3px solid #78979a;
}
.select_card_version input {
  width: 70%;
  border: 3px solid #78979a;
  z-index: 1;
  height: 32px;
  border-radius: 30px;
  color: #003a40;
  padding: 1rem;
  font-size: 1rem;
  margin: auto;
  margin-bottom: 1rem;
}

.container-scrollable {
  padding: 1px 0 1px 0;
  overflow: scroll;
  height: 180px;
}

.card {
  width: 80%;
  margin: auto;
}
.card li {
  position: relative;
  top: -1px;
  border-bottom: 3px solid #66d8e5;
  border-left: 3px solid #66d8e5;
  border-right: 3px solid #66d8e5;
  padding: 0.7rem;
}
.card li:hover {
  /* background: #fff4dd; */
  cursor: pointer;
}

.card li.selected {
  background: #2cbbc9;
}

.card li.selected p {
  color: #fff;
}

.card li:first-child {
  border-top: 3px solid #66d8e5;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.card li:last-child {
  border-bottom: 3px solid #66d8e5;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

input#selected_option {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  border-radius: 30px;
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 500px) {
  .wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .wrapper .title {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
  }
  .select_wrap {
    width: 85%;
    margin: 45px auto;
    margin-top: 10px;
    position: relative;
    user-select: none;
  }
  .select_wrap .default_option {
    height: 60px;
    background: #fff;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    border: 3px solid #336166;
    opacity: 0.66;
    z-index: 1;
  }
  .select_wrap .default_option li {
    padding: 0;
  }
  .select_wrap .default_option:before {
    content: '';
    position: absolute;
    top: 17px;
    right: 18px;
    width: 6px;
    height: 6px;
    border: 4px solid;
    border-color: transparent transparent #66d8e5 #66d8e5;
    transform: rotate(-45deg);
  }
  .select_wrap .select_ul {
    position: absolute;
    top: 58px;
    left: 0;
    width: 85%;
    background: #fff;
    border-radius: 5px;
    display: none;
    margin: 0 7%;
  }
  .select_wrap .select_ul li {
    padding: 0px 20px;
    cursor: pointer;
    border-bottom: 2px solid lightgrey;
  }

  .select_wrap .select_ul li:last-child {
    border-bottom: 0px;
  }

  .select_wrap .select_ul li:first-child:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .select_wrap .select_ul li:last-child:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  /* .select_wrap .select_ul li:hover {
    background: #fff4dd;
  } */
  .select_wrap .option.selected {
    display: flex;
    align-items: center;
    text-align: center;
    height: 53px;
  }

  .select_wrap .option {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 7px;
  }
  .select_wrap .option p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    font-size: 16px !important;
    font-weight: 700;
  }
  .select_ul li .option p {
    opacity: 0.66;
    margin-left: 0;
    text-align: left;
    /* font: normal normal 600 24px Segoe UI; */
    letter-spacing: 0px;
    color: #003a40;
  }

  .select_wrap.active .select_ul {
    display: block;
    border: 3px solid #66d8e5;
    border-top: 0px;
    border-radius: 0px 0px 25px 25px;
    opacity: 1;
    max-height: calc(90vh - 260px);
    overflow-y: scroll;
  }
  .select_wrap.active .default_option:before {
    top: 25px;
    transform: rotate(-225deg);
  }
}

@media (min-width: 500px) {
  .wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .wrapper .title {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
  }
  .select_wrap {
    width: 323px;
    margin: 45px auto;
    position: relative;
    user-select: none;
  }
  .select_wrap .default_option {
    height: 60px;
    background: #fff;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    border: 3px solid #336166;
    opacity: 0.66;
    z-index: 1;
  }
  .select_wrap .default_option li {
    padding: 0;
  }
  .select_wrap .default_option:before {
    content: '';
    position: absolute;
    top: 24px;
    right: 18px;
    width: 6px;
    height: 6px;
    border: 4px solid;
    border-color: transparent transparent #66d8e5 #66d8e5;
    transform: rotate(-45deg);
  }
  .select_wrap .select_ul {
    position: absolute;
    top: 58px;
    left: 0;
    width: 85%;
    background: #fff;
    border-radius: 5px;
    display: none;
    margin: 0 7%;
  }
  .select_wrap .select_ul li {
    padding: 0px 20px;
    cursor: pointer;
    border-bottom: 2px solid lightgrey;
  }
  .select_wrap .select_ul li:last-child {
    border-bottom: 0px;
  }
  .select_wrap .select_ul li:first-child:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .select_wrap .select_ul li:last-child:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .select_wrap .select_ul li:hover {
    background: #fff4dd;
  }
  .select_wrap .option {
    display: flex;
    align-items: center;
    text-align: center;
    height: 53px;
  }
  .select_wrap .option p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }
  .select_ul li .option p {
    opacity: 0.66;
    margin-left: 0;
  }
  .select_wrap .option .icon {
    background: url('https://i.imgur.com/oEZu0sK.png') no-repeat 0 0;
    width: 32px;
    height: 32px;
    margin-right: 15px;
  }
  .select_wrap .option.pizza .icon {
    background-position: 0 0;
  }
  .select_wrap .option.burger .icon {
    background-position: 0 -35px;
  }
  .select_wrap .option.ice .icon {
    background-position: 0 -72px;
  }
  .select_wrap .option.fries .icon {
    background-position: 0 -111px;
    width: 25px;
  }
  .select_wrap.active .select_ul {
    display: block;
    border: 3px solid #66d8e5;
    border-top: 0px;
    border-radius: 0px 0px 25px 25px;
    opacity: 1;
    max-height: 400px;
    overflow-y: scroll;
  }
  .select_wrap.active .default_option:before {
    top: 25px;
    transform: rotate(-225deg);
  }
}
