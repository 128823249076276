@import "https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  list-style: none;
}

@media (max-height: 620px) {
  h2 {
    font-size: 3.5vh !important;
  }

  #bouton_go {
    margin-top: 5vh;
  }
}

.select_wrap .select_ul li.top {
  background-color: #eafec8;
}

.select_card_version {
  width: 100%;
  text-align: center;
  margin: 0 auto 7px;
}

.select_card_version input:focus-visible {
  border: 3px solid #78979a;
}

.select_card_version input {
  width: 70%;
  z-index: 1;
  height: 32px;
  color: #003a40;
  border: 3px solid #78979a;
  border-radius: 30px;
  margin: auto auto 1rem;
  padding: 1rem;
  font-size: 1rem;
}

.container-scrollable {
  height: 180px;
  padding: 1px 0;
  overflow: scroll;
}

.card {
  width: 80%;
  margin: auto;
}

.card li {
  border-bottom: 3px solid #66d8e5;
  border-left: 3px solid #66d8e5;
  border-right: 3px solid #66d8e5;
  padding: .7rem;
  position: relative;
  top: -1px;
}

.card li:hover {
  cursor: pointer;
}

.card li.selected {
  background: #2cbbc9;
}

.card li.selected p {
  color: #fff;
}

.card li:first-child {
  border-top: 3px solid #66d8e5;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.card li:last-child {
  border-bottom: 3px solid #66d8e5;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

input#selected_option {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #0000;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
}

@media (max-width: 500px) {
  .wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .wrapper .title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
  }

  .select_wrap {
    width: 85%;
    user-select: none;
    margin: 10px auto 45px;
    position: relative;
  }

  .select_wrap .default_option {
    height: 60px;
    cursor: pointer;
    opacity: .66;
    z-index: 1;
    background: #fff;
    border: 3px solid #336166;
    border-radius: 30px;
    position: relative;
  }

  .select_wrap .default_option li {
    padding: 0;
  }

  .select_wrap .default_option:before {
    content: "";
    width: 6px;
    height: 6px;
    border: 4px solid #0000;
    border-color: #0000 #0000 #66d8e5 #66d8e5;
    position: absolute;
    top: 17px;
    right: 18px;
    transform: rotate(-45deg);
  }

  .select_wrap .select_ul {
    width: 85%;
    background: #fff;
    border-radius: 5px;
    margin: 0 7%;
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
  }

  .select_wrap .select_ul li {
    cursor: pointer;
    border-bottom: 2px solid #d3d3d3;
    padding: 0 20px;
  }

  .select_wrap .select_ul li:last-child {
    border-bottom: 0;
  }

  .select_wrap .select_ul li:first-child:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .select_wrap .select_ul li:last-child:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .select_wrap .option.selected {
    text-align: center;
    height: 53px;
    align-items: center;
    display: flex;
  }

  .select_wrap .option {
    text-align: center;
    align-items: center;
    padding: 7px;
    display: flex;
  }

  .select_wrap .option p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
    font-weight: 700;
    font-size: 16px !important;
  }

  .select_ul li .option p {
    opacity: .66;
    text-align: left;
    letter-spacing: 0;
    color: #003a40;
    margin-left: 0;
  }

  .select_wrap.active .select_ul {
    opacity: 1;
    max-height: calc(90vh - 260px);
    border: 3px solid #66d8e5;
    border-top: 0;
    border-radius: 0 0 25px 25px;
    display: block;
    overflow-y: scroll;
  }

  .select_wrap.active .default_option:before {
    top: 25px;
    transform: rotate(-225deg);
  }
}

@media (min-width: 500px) {
  .wrapper {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .wrapper .title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
  }

  .select_wrap {
    width: 323px;
    user-select: none;
    margin: 45px auto;
    position: relative;
  }

  .select_wrap .default_option {
    height: 60px;
    cursor: pointer;
    opacity: .66;
    z-index: 1;
    background: #fff;
    border: 3px solid #336166;
    border-radius: 30px;
    position: relative;
  }

  .select_wrap .default_option li {
    padding: 0;
  }

  .select_wrap .default_option:before {
    content: "";
    width: 6px;
    height: 6px;
    border: 4px solid #0000;
    border-color: #0000 #0000 #66d8e5 #66d8e5;
    position: absolute;
    top: 24px;
    right: 18px;
    transform: rotate(-45deg);
  }

  .select_wrap .select_ul {
    width: 85%;
    background: #fff;
    border-radius: 5px;
    margin: 0 7%;
    display: none;
    position: absolute;
    top: 58px;
    left: 0;
  }

  .select_wrap .select_ul li {
    cursor: pointer;
    border-bottom: 2px solid #d3d3d3;
    padding: 0 20px;
  }

  .select_wrap .select_ul li:last-child {
    border-bottom: 0;
  }

  .select_wrap .select_ul li:first-child:hover {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .select_wrap .select_ul li:last-child:hover {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .select_wrap .select_ul li:hover {
    background: #fff4dd;
  }

  .select_wrap .option {
    text-align: center;
    height: 53px;
    align-items: center;
    display: flex;
  }

  .select_wrap .option p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .select_ul li .option p {
    opacity: .66;
    margin-left: 0;
  }

  .select_wrap .option .icon {
    width: 32px;
    height: 32px;
    background: url("https://i.imgur.com/oEZu0sK.png") no-repeat;
    margin-right: 15px;
  }

  .select_wrap .option.pizza .icon {
    background-position: 0 0;
  }

  .select_wrap .option.burger .icon {
    background-position: 0 -35px;
  }

  .select_wrap .option.ice .icon {
    background-position: 0 -72px;
  }

  .select_wrap .option.fries .icon {
    width: 25px;
    background-position: 0 -111px;
  }

  .select_wrap.active .select_ul {
    opacity: 1;
    max-height: 400px;
    border: 3px solid #66d8e5;
    border-top: 0;
    border-radius: 0 0 25px 25px;
    display: block;
    overflow-y: scroll;
  }

  .select_wrap.active .default_option:before {
    top: 25px;
    transform: rotate(-225deg);
  }
}

/*# sourceMappingURL=index.ddb1cd3f.css.map */
